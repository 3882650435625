const tuitionVarArray = [
  {
    tuitionId: '20228U1',
    seasonYear: '2022',
    teamId: '8U1',
    teamName: 'Mite Major',
    paypalButtonId: '89ZUFYWE636C2',
    paypalButtonIdDev: '4PJL69526DVDL',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=10971d43-fb53-4ea1-a8d5-6b8b99b74d4f&amount=2180&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Mite%20Major',
      installmentUrlDemo: 'https://demo.partial.ly/checkout?offer=9cdb2e45-8fb4-4ab5-a33c-818c6b18f6d2&amount=2180&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Mite%20Major',
      installmentTotal: '$2180',
      installmentSummary: '$2180 - $375 deposit, 6x $361 monthly payments'
    },
    total: '$2140',
    totalNum: 2140
  },
  {
    tuitionId: '20228U2',
    seasonYear: '2022',
    teamId: '8U2',
    teamName: 'Mite Minor',
    paypalButtonId: '4GXXZCCA9U47G',
    paypalButtonIdDev: 'FLXSNDK9Z43HU',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=e4f9a7d6-1589-4255-a5b2-ecc4794906d5&amount=1810&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Mite%20Minor',
      installmentUrlDemo: 'https://demo.partial.ly/checkout?offer=0eaeb74a-a586-4376-89ed-3a80fc3f3ad6&amount=1810&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Mite%20Minor',
      installmentTotal: '$1810',
      installmentSummary: '$1810 - $325 deposit, 5x $297 monthly payments'
    },
    total: '$1775',
    totalNum: 1775
  },
  {
    tuitionId: '202210U',
    seasonYear: '2022',
    teamId: '10U',
    teamName: 'Squirt',
    paypalButtonId: '8V9Z2QCKQ7KL6',
    paypalButtonIdDev: 'N8D72P8RFCFBN',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=dd4b812e-fa4b-4550-b278-e99d874f0832&amount=3100&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Squirt',
      installmentUrlDemo: 'https://demo.partial.ly/checkout?offer=a98dffa1-5a31-4f0a-83e5-6f930cc405d0&amount=3100&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Squirt',
      installmentTotal: '$3100',
      installmentSummary: '$3100 - $450 deposit, 5x $530 monthly payments'
    },
    total: '$3040',
    totalNum: 3040
  },
  {
    tuitionId: '202210UP',
    seasonYear: '2022',
    teamId: '10U',
    teamName: 'Squirt',
    paypalButtonId: 'KR7VKMYF8HU22',
    paypalButtonIdDev: 'S44UJ6C4U8SRE',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=40ae24a3-c05f-432a-b059-8e01a88a6c32&amount=2310&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Squirt%20-%20PRACTICE',
      installmentUrlDemo: 'https://demo.partial.ly/checkout?offer=dc0e6f7a-82b6-4741-a70f-908ad65be99b&amount=2310&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Squirt%20-%20PRACTICE',
      installmentTotal: '$2310',
      installmentSummary: '$2310 - $450 deposit, 5x $372 monthly payments'
    },
    total: '$2265',
    totalNum: 2265,
    practice: true
  },
  {
    tuitionId: '202212U',
    seasonYear: '2022',
    teamId: '12U',
    teamName: 'Pee-Wee',
    paypalButtonId: 'YMVPKJ6JXUD3Q',
    paypalButtonIdDev: 'MRADCUYFWET7W',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=499054c6-8d52-4bfe-b673-09be37b9a3f8&amount=3100&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Pee-Wee%20(12U)',
      installmentUrlDemo: 'https://demo.partial.ly/checkout?offer=4c380b81-5759-4eee-a5c3-3f0c4e784285&amount=3100&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Pee-Wee%20(12U)',
      installmentTotal: '$3100',
      installmentSummary: '$3100 - $400 deposit, 5x $530 monthly payments'
    },
    total: '$3040',
    totalNum: 3040
  },
  {
    tuitionId: '202212UP',
    seasonYear: '2022',
    teamId: '12U',
    teamName: 'Pee-Wee',
    paypalButtonId: '7D3N25XNYUWCU',
    paypalButtonIdDev: 'BW9P6MXJCDD7C',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=45692b45-e0ac-4253-8e73-e31abaea51b3&amount=2310&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Pee-Wee%20(12U)%20-%20PRACTICE',
      installmentUrlDemo: 'https://demo.partial.ly/checkout?offer=38cf6723-0173-4254-9618-97d8ddbfc6e8&amount=2310&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Pee-Wee%20(12U)%20-%20PRACTICE',
      installmentTotal: '$2310',
      installmentSummary: '$2310 - $450 deposit, 5x $372 monthly payments'
    },
    total: '$2265',
    totalNum: 2265,
    practice: true
  },
  {
    tuitionId: '202212UA2',
    seasonYear: '2022',
    teamId: '12UA2',
    teamName: 'Pee-Wee',
    paypalButtonId: 'UXMCTC29XSN62',
    paypalButtonIdDev: 'LAQ5KRETKVRNL',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=3b0e6d02-806f-4b16-a9e4-c8a1db672b58&amount=3100&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Pee-Wee%20(12UA2)',
      installmentUrlDemo: 'https://demo.partial.ly/checkout?offer=ee5080e0-9771-458b-a089-088003e8b4e0&amount=3100&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Pee-Wee%20(12UA2)',
      installmentTotal: '$3100',
      installmentSummary: '$3100 - $450 deposit, 5x $530 monthly payments'
    },
    total: '$3040',
    totalNum: 3040
  },
  {
    tuitionId: '202214U',
    seasonYear: '2022',
    teamId: '14U',
    teamName: 'Bantam',
    paypalButtonId: 'NE7YAPQTSPUUN',
    paypalButtonIdDev: '76RJJX7NJ6D68',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=efdf9b5e-41b4-4370-b935-5b80bce4dea1&amount=3455&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Bantam',
      installmentUrlDemo: 'https://demo.partial.ly/checkout?offer=3089c995-6862-428e-bd16-c190b5378623&amount=3455&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Bantam',
      installmentTotal: '$3455',
      installmentSummary: '$3445 - $580 deposit, 5x $573 monthly payments'
    },
    total: '$3380',
    totalNum: 3380
  },
  {
    tuitionId: '202216U',
    seasonYear: '2022',
    teamId: '16U',
    teamName: 'Midget',
    paypalButtonId: 'L9PCGZ3G7RVGE',
    paypalButtonIdDev: 'AQHDVVFV4ERAE',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=9c3915bc-ddc8-4039-93dc-028b5af2ca07&amount=3445&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20U16AA',
      installmentUrlDemo: 'https://demo.partial.ly/checkout?offer=a3add928-c288-48fa-9de0-c2fb9228bd7b&amount=3445&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20U16AA',
      installmentTotal: '$3455',
      installmentSummary: '$3445 - $580 deposit, 5x $573 monthly payments'
    },
    total: '$3380',
    totalNum: 3380
  },
  {
    tuitionId: '202310U',
    seasonYear: '2023',
    teamId: '10U',
    teamName: 'Squirt',
    paypalButtonId: 'B3GNHULCRVKYY',
    paypalButtonIdDev: '',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=94885681-36cd-49d2-95f7-f98b7c50fe57&amount=2825&meta[description]=2023-2024%20Bulldogs%20Tuition%20-%20U10',
      installmentUrlDemo: '',
      installmentTotal: '$2825',
      installmentSummary: '$2825 - 5x $565 monthly payments'
    },
    total: '$2785',
    totalNum: 2785
  },
  {
    tuitionId: '202310UP',
    seasonYear: '2023',
    teamId: '10UP',
    teamName: 'Squirt',
    paypalButtonId: '8L5N9CNS7JAR8',
    paypalButtonIdDev: '',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=2010bcfc-865c-412f-9c33-32207e671bc4&amount=2000&meta[description]=2023-2024%20Bulldogs%20Tuition%20-%20U10%20PRACTICE',
      installmentUrlDemo: '',
      installmentTotal: '$2000',
      installmentSummary: '$2000 - 5x $400 monthly payments'
    },
    total: '$1970',
    totalNum: 1970,
    practice: true
  },
  {
    tuitionId: '202312UA1',
    seasonYear: '2023',
    teamId: '12UA1',
    teamName: 'Pee-Wee',
    paypalButtonId: 'HDDYMD42M76TQ',
    paypalButtonIdDev: '',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=a3966093-2550-48d7-a494-cd6c09166ada&amount=2825&meta[description]=2023-2024%20Bulldogs%20Tuition%20-%20U12A1',
      installmentUrlDemo: '',
      installmentTotal: '$2825',
      installmentSummary: '$2825 - 5x $565 monthly payments'
    },
    total: '$2785',
    totalNum: 2785
  },
  {
    tuitionId: '202312UP',
    seasonYear: '2023',
    teamId: '12UP',
    teamName: 'Pee-Wee',
    paypalButtonId: 'PBK7THQEFH3WC',
    paypalButtonIdDev: '',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=dfdb52cb-6b44-43a1-a7d3-c46a3f384792&amount=1475&meta[description]=2023-2024%20Bulldogs%20Tuition%20-%20U12%20-%20PRACTICE',
      installmentUrlDemo: '',
      installmentTotal: '$1475',
      installmentSummary: '$1475 - 5x $295 monthly payments'
    },
    total: '$1450',
    totalNum: 1450,
    practice: true
  },
  {
    tuitionId: '202312UA2',
    seasonYear: '2023',
    teamId: '12UA2',
    teamName: 'Pee-Wee',
    paypalButtonId: '3TVHXGP7WM4DW',
    paypalButtonIdDev: '',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=8de3a406-5333-4700-878d-8cc810087dfd&amount=2825&meta[description]=2023-2024%20Bulldogs%20Tuition%20-%20U12A2',
      installmentUrlDemo: '',
      installmentTotal: '$2825',
      installmentSummary: '$2825 - 5x $565 monthly payments'
    },
    total: '$2785',
    totalNum: 2785
  },
  {
    tuitionId: '202314U',
    seasonYear: '2023',
    teamId: '14U',
    teamName: 'Bantam',
    paypalButtonId: '67SRPS7MMBRMU',
    paypalButtonIdDev: '',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=0239bb03-3ce7-4ae1-a9a3-c02218fa3ed7&amount=3025&meta[description]=2023-2024%20Bulldogs%20Tuition%20-%20U14',
      installmentUrlDemo: '',
      installmentTotal: '$3025',
      installmentSummary: '$3025 - 5x $605 monthly payments'
    },
    total: '$2975',
    totalNum: 2975
  },
  {
    seasonYearFull: '2024-2025',
    tuitionId: '2024yeti',
    seasonYear: '2024',
    teamId: 'Yetis',
    teamName: 'All Divisions',
    paypalButtonId: 'QPXFZ59DXRYHG',
    paypalButtonIdDev: 'UPH2BDBNUA68W',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=5e845907-7901-49a9-a377-9bf7a7405b9e&amount=1575&meta[description]=2024%20Yetis%20Tuition%20%241575%20-%20%24525%20Deposit%2C%20%24525%20due%202%2F15%2C%20%24525%20due%203%2F15.&referral_source=shared_link',
      installmentUrlDemo: '',
      installmentTotal: '$1575',
      installmentSummary: '$1575 - $525 Deposit, $525 due 2/15, $525 due 3/15'
    },
    total: '$1550',
    totalNum: 1550
  },
  {
    seasonYearFull: '2024-2025',
    tuitionId: '2024yetipracticeonly',
    seasonYear: '2024',
    teamId: 'Yetis (Practice Only)',
    teamName: 'All Divisions',
    paypalButtonId: 'U42MNM9LTEBCG',
    paypalButtonIdDev: '',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=18c11269-180b-4a9c-adc9-7c1cd87a0009&amount=800&meta[description]=2024%20Yetis%20Tuition%20%24800%20-%20%24200%20Deposit%2C%20%24300%20due%202%2F15%2C%20%24300%20due%203%2F15.&referral_source=shared_link',
      installmentUrlDemo: '',
      installmentTotal: '$800',
      installmentSummary: '$800 - $200 Deposit, $300 due 2/15, $300 due 3/15'
    },
    total: '$800',
    totalNum: 800,
    practice: true
  },
  {
    seasonYearFull: '2024-2025',
    tuitionId: '2024hsstampede',
    seasonYear: '2024',
    teamId: 'Highschool Stampede',
    teamName: 'All Divisions',
    paypalButtonId: 'YE88UHB6ZWYHS',
    paypalButtonIdDev: '',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=05ea3c03-a925-4197-8b90-8532811ed236&amount=677&meta[description]=%24677%20-%20%24200%20Deposit%2C%20%24477%20remaining%20balance%20due%20the%20following%20month&referral_source=shared_link',
      installmentUrlDemo: '',
      installmentTotal: '$677',
      installmentSummary: '$677 - $200 Deposit, $477 remaining balance due the following month'
    },
    total: '$667',
    totalNum: 667,
    practice: false
  },
  {
    tuitionId: '202410UTUITION',
    seasonYear: '2024',
    teamId: '10U',
    teamName: 'Squirt',
    paypalButtonId: 'TU5HHXM4C9TAW',
    paypalButtonIdDev: '',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=c271535a-14ba-4f89-8d7b-0173656a6ab4&amount=3568&meta[description]=2024-2025%20Bulldogs%20Tuition%20-%20Squirt%20(10U)%09&referral_source=shared_link',
      installmentUrlDemo: '',
      installmentTotal: '$3568',
      installmentSummary: '$3568 - $600 Deposit, 7x $424 monthly payments'
    },
    total: '$3514',
    totalNum: 3514
  },
  {
    tuitionId: '202412UTUITION',
    seasonYear: '2024',
    teamId: '12U',
    teamName: 'Pee-Wee',
    paypalButtonId: 'ME7DUMW2WVV8U',
    paypalButtonIdDev: '',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=4e0caba0-d0c1-4104-8954-7b0a41a7a6f8&amount=3568&meta[description]=2024-2025%20Bulldogs%20Tuition%20-%20Pee-Wee%20(12U)%09&referral_source=shared_link',
      installmentUrlDemo: '',
      installmentTotal: '$3568',
      installmentSummary: '$3568 - $600 Deposit, 7x $424 monthly payments'
    },
    total: '$3514',
    totalNum: 3514
  },
  {
    tuitionId: '202414UTUITION',
    seasonYear: '2024',
    teamId: '14U',
    teamName: 'Bantam',
    paypalButtonId: 'UGYA36XH96Y3A',
    paypalButtonIdDev: '',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=d917de23-bcd0-4016-a9ff-f96b7b53563f&amount=3834&meta[description]=2024-2025%20Bulldogs%20Tuition%20-%20Bantam%20(14U)&referral_source=shared_link',
      installmentUrlDemo: '',
      installmentTotal: '$3834',
      installmentSummary: '$3834 - $600 Deposit, 7x $462 monthly payments'
    },
    total: '$3778',
    totalNum: 3778
  },
  {
    tuitionId: '202416UTUITION',
    seasonYear: '2024',
    teamId: '16U',
    teamName: 'Midget',
    paypalButtonId: 'NTWGMHQYWYHFC',
    paypalButtonIdDev: '',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=925647c0-6a81-49e6-9e37-c39b7b8d6e43&amount=3834&meta[description]=2024-2025%20Bulldogs%20Tuition%20-%20Midget%20(16U)&referral_source=shared_link',
      installmentUrlDemo: '',
      installmentTotal: '$3834',
      installmentSummary: '$3834 - $600 Deposit, 7x $462 monthly payments'
    },
    total: '$3778',
    totalNum: 3778
  },{
    tuitionId: '20248UTRAVELDEPOSIT',
    seasonYear: '2024',
    teamId: '8U',
    teamName: 'Mites',
    paypalButtonId: '89ZUFYWE636C2',
    paypalButtonIdDev: '',
    installment: {
      installmentUrl: 'https://partial.ly/checkout?offer=10971d43-fb53-4ea1-a8d5-6b8b99b74d4f&amount=2180&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Mite%20Major',
      installmentUrlDemo: 'https://demo.partial.ly/checkout?offer=9cdb2e45-8fb4-4ab5-a33c-818c6b18f6d2&amount=2180&meta[description]=2022-2023%20Bulldogs%20Tuition%20-%20Mite%20Major',
      installmentTotal: '$2180',
      installmentSummary: '$2180 - $375 deposit, 6x $361 monthly payments'
    },
    total: '$2140',
    totalNum: 2140
  },
];

export default tuitionVarArray;
